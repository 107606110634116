import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { waitFor } from '@ember/test-waiters';
import { apiBaseURL } from 'qonto/constants/hosts';

export type FetchFunction = (path: string, options?: RequestInit) => Promise<Response>;

export function useFetchApi(): FetchFunction {
  const networkManager = useEmberService('network-manager');
  return waitFor(async (path: string, options: RequestInit = {}) => {
    return await fetch(`${apiBaseURL}/${path}`, {
      ...options,
      headers: { ...networkManager.requestHeaders, ...options.headers },
      credentials: 'include',
    });
  });
}
