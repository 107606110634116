import { useMutation, type UseMutateFunction } from '@tanstack/react-query';
import { transactionNamespace } from 'qonto/constants/hosts';
import { useFetchApi } from './use-fetch-api';

interface MutationPayload {
  transactionsIds: string[];
  categoryId: string;
}

export const useAssignTransactionsCategory = (): {
  mutate: UseMutateFunction<void, Error, MutationPayload>;
} => {
  const fetchApi = useFetchApi();

  const assignTransactionsCategory = async (payload: MutationPayload): Promise<void> => {
    try {
      const response = await fetchApi(`${transactionNamespace}/cash-flow/categories/assignments`, {
        method: 'POST',
        body: JSON.stringify({
          transaction_ids: payload.transactionsIds,
          category_id: payload.categoryId,
        }),
      });

      if (!response.ok) throw Error('Category could not be saved');
    } catch (e) {
      throw new Error('Category could not be saved');
    }
  };

  const { mutate } = useMutation({
    mutationFn: assignTransactionsCategory,
  });

  return { mutate };
};
