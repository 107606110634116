import { useLocalStorage } from 'usehooks-ts';

type Collection = 'cash-flow-category-selector-expanded-states';

export const useCashFlowCategoriesStateStorage = (
  selectedCollection: Collection,
  key: string
): {
  isExpanded: boolean;
  setIsExpanded: (value: boolean) => void;
} => {
  const [collection, setCollection] = useLocalStorage<Record<string, boolean>>(
    selectedCollection,
    {}
  );

  const isExpanded = collection[key] ?? false;

  const setIsExpanded = (value: boolean): void => {
    setCollection({ ...collection, [key]: value });
  };

  return {
    isExpanded,
    setIsExpanded,
  };
};
